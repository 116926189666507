export enum BrowserType {
  Chrome,
  Firefox,
  IE,
  Safari,
  Opera,
  Other,
}

export enum DeviceType {
  Desktop,
  Tablet,
  Mobile,
}

export interface BrowserInfo {
  browser: BrowserType;
  version: number;
  device: DeviceType;
}

function getBrowserType(name: string): BrowserType {
  switch (name.toLowerCase()) {
    case 'chrome':
    case 'opera':
      return BrowserType.Chrome;
    case 'firefox':
      return BrowserType.Firefox;
    case 'safari':
      return BrowserType.Safari;
    case 'msie':
    case 'ie':
      return BrowserType.IE;
    default:
      return BrowserType.Other;
  }
}

function getDeviceType(): DeviceType {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/Blackberry/i)
  ) {
    return DeviceType.Mobile;
  } else if (navigator.userAgent.match(/iPad/i)) {
    return DeviceType.Tablet;
  }
  return DeviceType.Desktop;
}

export function getInfo(): BrowserInfo {
  var ua = navigator.userAgent;
  var parts: string[] = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  var tem: string[] = [];
  if (/trident/i.test(parts[1])) {
    tem = <string[]>(/\brv[ :]+(\d+)/g.exec(ua) || []);
    return { browser: BrowserType.IE, version: parseInt(tem[1], 10), device: getDeviceType() };
  }
  if (parts[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      // opera
      return { browser: BrowserType.Opera, version: parseInt(tem[1]), device: getDeviceType() };
    }
    tem = ua.match(/\bEdge\/(\d+)/);
    if (tem != null) {
      // msEdge
      return { browser: BrowserType.IE, version: parseInt(tem[1]), device: getDeviceType() };
    }
  }
  parts = parts[2] ? [parts[1], parts[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
    parts.splice(1, 1, tem[1]);
  }
  return {
    browser: getBrowserType(parts[0]),
    version: parseInt(parts[1]),
    device: getDeviceType(),
  };
}

export function isSupported(): boolean {
  var info = getInfo();
  if (info.device === DeviceType.Desktop) {
    if (info.browser === BrowserType.Chrome && info.version >= 37) {
      return true;
    }
    if (info.browser === BrowserType.Firefox && info.version >= 33) {
      return true;
    }
    if (info.browser === BrowserType.Opera && info.version >= 25) {
      return true;
    }
    if (info.browser === BrowserType.IE && info.version >= 10) {
      return true;
    }
    if (info.browser === BrowserType.Safari && info.version >= 7) {
      return true;
    }
  }
  return false;
}
