import React from 'react';
import { isArray } from 'lodash';
import { useAppSelector } from '@wt-nx/wt-client/app-state/logic';
import { createPathSelector } from 'reselect-utils';
import { fakeModeSegmentSelector } from '@wt-nx/auth/client/domain';
import { symbolsSegmentSelector } from '@wt-nx/symbols/client/domain';
import { Screen } from '@wt-nx/navigation/client/domain';
import { SymbolsTable } from '@wt-nx/wt-client/frontend-common-data/symbols/_components/symbols-table/symbols-table';

interface ISymbolsTableDesktopWrapperProps {
  action: (symbol?: string) => void;
}

export const SymbolsTableDesktopWrapper: React.FC<ISymbolsTableDesktopWrapperProps> = React.memo((props) => {
  const isVirtual = useAppSelector(createPathSelector(fakeModeSegmentSelector).isVirtualMode());
  const areFavoritesVisible = useAppSelector(createPathSelector(symbolsSegmentSelector).areFavoritesVisible());
  const screen = areFavoritesVisible ? Screen.Favorites : Screen.AllSymbols;
  const { action } = props;

  const extractId = React.useCallback((child: any) => {
    if (isArray(child?.children)) {
      const symbolCell = child.children[1] as HTMLElement;
      return symbolCell?.children[0]?.id;
    }
  }, []);

  return <SymbolsTable hideFavorites={isVirtual} action={action} screen={screen} extractId={extractId} />;
});

SymbolsTableDesktopWrapper.displayName = 'SymbolsTableDesktopWrapper';