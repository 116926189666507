import * as React from 'react';
import { t } from '@wt-nx/wt-client/i18n/domain';

export const SymbolsTableChatTab = React.memo(() => {
  const intervalRef = React.useRef(null);
  const [isWidgetLoaded, setIsWidgetLoaded] = React.useState(false);
  React.useEffect(() => {
    // we have to display this component only if widget is loaded
    intervalRef.current = setInterval(() => {
      if (typeof (window as any).$respond !== 'undefined') {
        clearInterval(intervalRef.current);
        setIsWidgetLoaded(true);
      }
    }, 100);
  }, []);

  if (!isWidgetLoaded) {
    return null;
  }

  return <div>{t('$LiveChat_Title')}</div>;
});

SymbolsTableChatTab.displayName = 'SymbolsTableChatTab';
