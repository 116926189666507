import { ScreenAction } from './screen.action';
import { IScreenStore, Screen } from './screen.interface';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { SCREEN_INITIAL_STATE } from './screen.init-state';

export const screenReducer = reducerWithInitialState<IScreenStore>(SCREEN_INITIAL_STATE)
  .case(ScreenAction.Change, (state, payload) => {
    const { screen } = payload;
    const isFullScreen =
      screen === Screen.FullScreenChart ||
      screen === Screen.FullScreenCreatePosition ||
      screen === Screen.FullScreenQuickTradingSettings;

    return {
      ...state,
      currentScreen: screen,
      isFullScreen,
    };
  })
  .case(ScreenAction.SetBackFromInitialScreen, (state, payload) => {
    return {
      ...state,
      backScreen: payload,
      isInitialScreen: true,
    };
  })
  .case(ScreenAction.ResetBackFromInitialScreen, (state) => {
    return {
      ...state,
      backScreen: undefined,
      isInitialScreen: false,
    };
  });
