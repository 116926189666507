import type { ICommonLkUrls } from '@wt-nx/wt-cs/site-n-lk-urls';
import type { IAccountShortDataDto, IActiveAccountRespDto } from '@wt-nx/wt-cs/accounts';
import type { IQuickRegistrationResDataForClient } from '@wt-nx/registration/cs';
import { Jurisdiction } from '@wt-nx/wt-cs/jurisdiction';
import { Functionality } from '@wt-nx/app-config/cs';

export interface IUserInfo {
  id: number;
  clientId: string;
  email: string;
  isTest: boolean; // from webtrader-site
  isDemo: boolean;
}

export enum VersionCheck {
  UpdateNotNeeded,
  ForceUpdate,
  RecommendUpdate,
}

export type IAuthDataDto = ICommonLkUrls & {
  userInfo: IUserInfo;
  token: string;
  activeAccount: IActiveAccountRespDto;
  accounts: IAccountShortDataDto[];
  settings: any; // HAD TYPE IUserSettings IN from webtrader-site
  settingsDate: number;
  ntfSettings: any; // HAD TYPE IUserSettings IN from webtrader-site
  ntfSettingsDate: number;
  serverTimeZoneOffset: number;
  serviceTimeMs: number; //  // from webtrader-mobile
  registrationData?: IQuickRegistrationResDataForClient;
  versionCheck: VersionCheck;
  jurisdiction: Jurisdiction;
  copyFxAllowed: boolean; // DEPRECATED, left for backward compatibility
  iosTradeAllowed: boolean;
  shouldReportZombies?: boolean;
  isKerTestRequired: boolean;
  isKerTestPassed: boolean;
  lkPushNotificationConfig?: any;
  office: string;
  allowedFunctionalities?: Functionality[];
};
