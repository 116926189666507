import type { ITheme } from '@wt-nx/wt-client/theme';
import { css } from '@wt-nx/wt-client/theme';
import { withBaseClasses } from './copyfx-subscription-confirmation-contents.css.base';

export const withClasses = (theme: ITheme) => {
  const { $on_prime } = theme.palette.on;

  return {
    ...withBaseClasses(theme),
    chkContents: css({}),
    disclaimer: css({
      color: $on_prime,
      ...theme.fonts.body.body_6_14pt_r,
    }),
  };
};
