import React from 'react';
import { createPathSelector } from 'reselect-utils';
import { useAppSelector } from '@wt-nx/wt-client/app-state/logic';
import { t } from '@wt-nx/wt-client/i18n/domain';
import { IconType } from '@wt-nx/components/domain';
import { DataTestIds } from '@wt-nx/autotests';
import { getSymbolsTableClasses } from '@wt-nx/wt-client/frontend-common-data/symbols/_components/symbols-table/symbols-table.classes';
import { block } from '@wt-nx/wt-client/utils';
import { fakeModeSegmentSelector } from '@wt-nx/auth/client/domain';
import { SymbolsTableTabDesktop } from './tab/symbols-table-tab.desktop';

interface ISymbolsTableHeaderProps {
  headerRef: (h: HTMLElement) => void;
}

const classes = getSymbolsTableClasses();

const symbolsName = 'symbols';
const symbolsBlock = block(symbolsName);
const headerClass = symbolsBlock.elem('header');
const tabsClass = symbolsBlock.elem('tabs');
const tabClass = symbolsBlock.elem('tab');

export const SymbolsTableHeader: React.FC<ISymbolsTableHeaderProps> = React.memo((props) => {
  const isVirtual = useAppSelector(createPathSelector(fakeModeSegmentSelector).isVirtualMode());
  const { headerRef } = props;

  return (
    <div className={headerClass} ref={headerRef}>
      {isVirtual ? null : (
        <div className={tabsClass}>
          <SymbolsTableTabDesktop
            id={'all-symbols-tab'}
            label={t('$Symbol_Symbols')}
            className={tabClass}
            icon={IconType.List}
            dispatchWhenVisible={true}
            testEntity={{ id: DataTestIds.Symbols.AllSymbolsTab }}
          />
          <SymbolsTableTabDesktop
            id={'favorites-tab'}
            label={t('$Symbol_Favorites')}
            className={tabClass}
            icon={IconType.Favorite}
            dispatchWhenVisible={false}
            testEntity={{ id: DataTestIds.Symbols.FavoritesTab }}
          />
        </div>
      )}
      <div className={classes.thead}>
        <div className={classes.favorite} />
        <div className={classes.symbol}>{t('$Symbol_Symbol')}</div>
        <div className={classes.quote}>{t('$Symbol_Bid')}</div>
        <div className={classes.quote}>{t('$Symbol_Ask')}</div>
        <div className={classes.spread}>{t('$Symbol_Spread')}</div>
      </div>
    </div>
  );
});

SymbolsTableHeader.displayName = 'SymbolsTableHeader';
