import React from 'react';
import { createPathSelector } from 'reselect-utils';
import { toggleAreFavoritesVisibleThunk } from '@wt-nx/wt-client/frontend-common-data/symbols/_thunks/change-are-favorites-visible.thunk.desktop';
import { Tab } from '@wt-nx/wt-client/frontend-common-data/controls/Tab/Tab';
import { useAppDispatch, useAppSelector } from '@wt-nx/wt-client/app-state/logic';
import { symbolsSegmentSelector } from '@wt-nx/symbols/client/domain';
import type { ISymbolsTabOwnProps } from './symbols-table-tab.interface';

export const SymbolsTableTabDesktop: React.FC<ISymbolsTabOwnProps> = React.memo((props) => {
  const dispatch = useAppDispatch();
  const areFavoritesVisible = useAppSelector(createPathSelector(symbolsSegmentSelector).areFavoritesVisible());
  const { dispatchWhenVisible } = props;

  const onClick = React.useCallback(() => {
    if ((areFavoritesVisible && dispatchWhenVisible) || (!areFavoritesVisible && !dispatchWhenVisible)) {
      dispatch(toggleAreFavoritesVisibleThunk());
    }
  }, [areFavoritesVisible, dispatchWhenVisible]);

  const isActive = (areFavoritesVisible && !dispatchWhenVisible) || (!areFavoritesVisible && dispatchWhenVisible);
  return (
    <Tab
      {...props}
      active={isActive}
      onClick={onClick}
      testEntity={{ ...props.testEntity, data: { 'is-selected': isActive } }}
    />
  );
});

SymbolsTableTabDesktop.displayName = 'SymbolsTableTabDesktop';
