import { navigationRef, Screen } from '@wt-nx/navigation/client/domain';
import type { ISymbolsStoreSegment } from '@wt-nx/symbols/client/domain';
import { symbolsSegmentSelector } from '@wt-nx/symbols/client/domain';
import type { IThunk } from '@wt-nx/wt-client/utils';
import type { ISettingsSaversStoreSegment } from '@wt-nx/settings-savers/client/domain';
import { setAreFavoritesVisibleThunk } from '@wt-nx/settings-savers/client/feature';

type IToggleAreFavoritesVisibleThunkState = ISymbolsStoreSegment & ISettingsSaversStoreSegment;

export const toggleAreFavoritesVisibleThunk: IThunk<void, IToggleAreFavoritesVisibleThunkState> = () => {
  return (dispatch, getState) => {
    const { areFavoritesVisible } = symbolsSegmentSelector(getState());
    dispatch(
      setAreFavoritesVisibleThunk({
        newAreFavoritesVisible: !areFavoritesVisible,
      }),
    );
    navigationRef.navigate(!areFavoritesVisible ? Screen.Favorites : Screen.AllSymbols);
  };
};
