import { includes, values } from 'lodash';
import { Screen } from '../screen/screen.interface';
import type { INavigationState } from '../navigator.interface';

export function getActiveRoute(navigationState: INavigationState): { name: Screen; params?: any } | undefined {
  if (!navigationState) {
    return;
  }
  const route = navigationState.routes[navigationState.index];
  // dive into nested navigators
  if (route.state?.routes?.length) {
    return getActiveRoute(route.state as INavigationState);
  }
  const screen = (route.params as any)?.screen;
  if (screen && includes(values(Screen), screen)) {
    return { name: screen, params: (route.params as any)?.params };
  }
  return { name: (route.name as unknown) as Screen, params: route.params };
}
