import * as React from 'react';
import { block } from '@wt-nx/wt-client/utils';
import { IconFa } from '@wt-nx/wt-client/components/icons';
import type { ITabProps } from './tab.interface';
import { analyticsOnClick } from '@wt-nx/analytics/client/domain';
import { prepareTestData } from '@wt-nx/autotests';

const tabName = 'tab';
const tabBlock = block(tabName);
const tabClass = tabName;
const tabActiveClass = tabBlock.block.mod('active', 'true').toString();
const labelClass = tabBlock.elem('label');

export const Tab: React.FC<ITabProps> = (props) => {
  const { label, className, icon, active, id, children, testEntity } = props;
  const onClick = () => {
    analyticsOnClick(id);
    props.onClick();
  };

  return (
    <button
      disabled={active}
      className={`${active ? tabActiveClass : tabClass} ${className}`}
      onClick={onClick}
      {...prepareTestData(testEntity)}
    >
      {icon ? <IconFa type={icon} /> : null}
      <span className={labelClass}>{label}</span>
      {children}
    </button>
  );
};
