import type { ITheme } from '@wt-nx/wt-client/theme';
import { css, getThemeHelpers } from '@wt-nx/wt-client/theme';
import { CSSObject } from '@emotion/react';

const themeHelpers = getThemeHelpers<CSSObject>();

export const withClasses = (theme: ITheme) => {
  const { $on_highlight } = theme.palette.on;
  const { $unit } = theme.spacings;

  return {
    container: css({
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'center',
      ...themeHelpers.marginVertical($unit * 6),
    }),
    text: css({
      ...theme.fonts.body.body_7_16pt_r,
      textAlign: 'center',
      color: $on_highlight,
    }),
  };
};
