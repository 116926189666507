import * as React from 'react';
import { Provider } from 'react-redux';
import { store } from '@wt-nx/wt-client/globals';
import { Block, Dimensions, Element, ISwitchCondition } from '@wt-nx/wt-client/utils';
import { changeSymbolsFilterThunk } from '@wt-nx/wt-client/frontend-common-data/symbols/_thunks/symbols-filter-change.thunk';
import * as fltrCtrl from '@wt-nx/wt-client/frontend-common-data/controls/Filter/Filter';
import { Symbol } from '@wt-nx/symbols/client/domain';
import { BySubstringFilter } from '@wt-nx/wt-client/frontend-common-data/models/Filtering/Filter';
import { openPositionScreenThunk } from '@wt-nx/wt-client/frontend-common-data/trading/open-position-screen.thunk';
import { t } from '@wt-nx/wt-client/i18n/domain';
import { WithThemeProviderWrapper } from '@wt-nx/wt-client/frontend-common-data/utils/react';
import { createRoot, Root } from 'react-dom/client';
import { RespondIoOnPageGrowthWidget } from '@wt-nx/respond-io/client/feature';
import { SymbolsTableDesktopWrapper } from './symbols-table/symbols-table-desktop-wrapper';
import { SymbolsTableHeader } from './symbols-table/symbols-table-header.desktop';
import { SymbolsTableChatTab } from './symbols-table/symbols-table-chat-tab.desktop';

const symbolsName = 'symbols';

export class SymbolBySubstringFilter extends BySubstringFilter<Symbol> {
  public qualifies(symbol: Symbol) {
    return symbol.name.toLowerCase().indexOf(this.getSearchValue()) > -1;
  }
}

export class SymbolsTableWrapper extends Block {
  private _root: Root;
  private _root2: Root;
  private _rootChatTab: Root;
  private _tableElement: Element;
  private _headerElement: Element;
  private _header: HTMLElement;
  private _chatTabElement: Element;

  constructor(symbolsVisibility: ISwitchCondition) {
    super(symbolsName);
    this.nodeId = symbolsName;

    const content = this.createElem('content');
    const filter = new SymbolBySubstringFilter();
    filter.onupdated.on((data) => {
      store.dispatch(changeSymbolsFilterThunk(data['search_str']));
    });
    const filterCtrl = new fltrCtrl.SubstringFilter<Symbol>(filter, t('$Symbol_Symbol'));
    content.appendAsElem(filterCtrl, 'filters');
    this.appendElem(content);

    this._headerElement = this.createElem('header');
    content.appendElem(this._headerElement);

    this._chatTabElement = this.createElem('chat-tab');
    content.appendElem(this._chatTabElement);

    this._tableElement = this.createElem('table');
    content.appendElem(this._tableElement);

    if (symbolsVisibility.calcSwitchState()) {
      this.showComponents();
    }
    symbolsVisibility.switchConditionEvents.forEach((event) =>
      this.subscribe(event, () => {
        const showHide = symbolsVisibility.calcSwitchState();
        if (showHide) {
          this.showComponents();
        } else {
          this.hideComponents();
        }
      }),
    );
  }

  private onSymbolItemClick(symbol?: string): void {
    symbol && store.dispatch(openPositionScreenThunk({ symbol }));
  }

  private showComponents() {
    this._root = this._root || createRoot(this._headerElement.html);
    this._root.render(
      <Provider store={store}>
        <WithThemeProviderWrapper>
          <SymbolsTableHeader
            headerRef={(h) => {
              this._header = h;
            }}
          />
        </WithThemeProviderWrapper>
      </Provider>,
    );

    this._root2 = this._root2 || createRoot(this._tableElement.html);
    this._root2.render(
      <Provider store={store}>
        <WithThemeProviderWrapper>
          <SymbolsTableDesktopWrapper action={this.onSymbolItemClick} />
        </WithThemeProviderWrapper>
        <RespondIoOnPageGrowthWidget requireAuthorization />
      </Provider>,
    );

    this._rootChatTab = this._rootChatTab || createRoot(this._chatTabElement.html);
    this._rootChatTab.render(
      <Provider store={store}>
        <WithThemeProviderWrapper>
          <SymbolsTableChatTab />
        </WithThemeProviderWrapper>
      </Provider>,
    );
  }

  private hideComponents() {
    this._root = this._root || createRoot(this._headerElement.html);
    this._root.render(null);

    this._root2 = this._root2 || createRoot(this._tableElement.html);
    this._root2.render(null);

    this._rootChatTab = this._rootChatTab || createRoot(this._chatTabElement.html);
    this._rootChatTab.render(null);
  }

  public setDimensions(dims: Dimensions) {
    super.setDimensions(dims);
    this.update();
  }

  public update() {
    if (this._header) {
      let headerHeight = 0;
      const headerStyle = getComputedStyle(this._header);

      if (headerStyle) {
        headerHeight = parseInt(headerStyle.height, 10);
      }
      if (headerHeight !== 0) {
        this._tableElement.setDimensions({ height: this.dimensions.height - headerHeight });
      }
    }
  }

  protected freeThis() {
    this.hideComponents();
    super.freeThis();
  }
}
