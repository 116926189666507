import type { IAccountsStoreSegment, IActiveAccountStoreSegment } from '@wt-nx/account/client/domain';
import type { IAnalyticsLoginParams } from '@wt-nx/analytics/client/domain';
import { AnalyticsService } from '@wt-nx/analytics/client/domain';
import type { IActiveUserStoreSegment } from '@wt-nx/user/client/domain';
import type { IThunk } from '@wt-nx/wt-client/utils';

export const analyticsLoginThunk: IThunk<
  IAnalyticsLoginParams,
  IActiveUserStoreSegment & IAccountsStoreSegment & IActiveAccountStoreSegment
> = (params) => {
  return (_dispatch, _getState) => {
    if (!params.userInfo.isDemo) {
      AnalyticsService.getInstance().trackLogin(params);
    }
  };
};
