(function () {
  var locales = {
    ru: {
      note: function (browser, version, supported) {
        return (
          'Вы используете ' +
          browser +
          ' версии ' +
          version +
          '. <br/>' +
          browser +
          ' поддерживается начиная с версии ' +
          supported +
          '.'
        );
      },
      warning: 'Вы используете устаревший браузер!',
      recommendation: 'Для корректной работы приложения загрузите и установите один из этих браузеров:',
    },
    en: {
      note: function (browser, version, supported) {
        return (
          "You're using " +
          browser +
          ' version ' +
          version +
          '. <br/>' +
          browser +
          ' is supported starting from version ' +
          supported +
          '.'
        );
      },
      warning: "You're using old browser version!",
      recommendation: 'Download and install one of these browsers for correct application operation:',
    },
    id: {
      note: function (browser, version, supported) {
        return (
          'Anda menggunakan ' +
          browser +
          ' versi' +
          version +
          '. <br/>' +
          browser +
          ' yang mendukung mulai dari versi ' +
          supported +
          '.'
        );
      },
      warning: 'Anda menggunakan browser versi lama!',
      recommendation: 'Download dan install salah satu browser ini untuk operasi aplikasi yang benar:',
    },
    my: {
      note: function (browser, version, supported) {
        return (
          'Anda menggunakan versi' +
          browser +
          ' ' +
          version +
          '. <br/>' +
          browser +
          ' ini disokong bermula dari versi ' +
          supported +
          '.'
        );
      },
      warning: 'Anda menggunakan versi pelayar lama!',
      recommendation: 'Muat turun dan pasangkan salah satu daripada pelayar ini untuk operasi permohonan yang betul:',
    },
    cn: {
      note: function (browser, version, supported) {
        return '您正在使用' + browser + '版' + version + '. <br/>从版本' + supported + '起支持此浏览器.';
      },
      warning: '您正在使用旧的浏览器',
      recommendation: '下载安装其中一个浏览器用来进行正确的应用操作:',
    },
    pl: {
      note: function (browser, version, supported) {
        return (
          'Używasz wersji' +
          browser +
          ' ' +
          version +
          '. <br/>' +
          browser +
          ' jest wspierana od wersji ' +
          supported +
          '.'
        );
      },
      warning: 'Używasz starej wersji przeglądarki!',
      recommendation: 'Pobierz i zainstaluj jedną z tych przeglądarek, aby aplikacja mogła prawidłowo działać:',
    },
    de: {
      note: function (browser, version, supported) {
        return (
          'Sie verwenden die ' +
          browser +
          ' - Version ' +
          version +
          '. <br/>' +
          browser +
          ' wird erst ab der Version ' +
          supported +
          ' unterstützt.'
        );
      },
      warning: 'Sie verwenden eine alte Browser-Version!',
      recommendation:
        'Laden Sie einen dieser Browser herunter und installieren Sie ihn, damit die Anwendung korrekt laufen kann:',
    },
    lt: {
      note: function (browser, version, supported) {
        return (
          'Jus naudojate ' +
          browser +
          ' versiją ' +
          version +
          '. <br/>' +
          browser +
          ' palaiko pradinę versiją ' +
          supported +
          '.'
        );
      },
      warning: 'Naudojate seną naršyklės versiją!',
      recommendation: 'Atsisiųsti ir įdiegti vieną iš šių naršyklių teisingai operacijai atlikti:',
    },
  };

  function getInfo() {
    var ua = navigator.userAgent;
    var parts = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    var tem = [];
    if (/trident/i.test(parts[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { browser: 'msie', version: parseInt(tem[1], 10) };
    }
    if (parts[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        // opera
        return { browser: 'opera', version: parseInt(tem[1]) };
      }
    }
    parts = parts[2] ? [parts[1], parts[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      parts.splice(1, 1, tem[1]);
    }
    return {
      browser: (parts[0] || '').toLowerCase(),
      version: parseInt(parts[1]),
    };
  }

  function getDeviceType() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/Blackberry/i)
    ) {
      return 'mobile';
      // eslint-disable-next-line no-dupe-else-if
    } else if (navigator.userAgent.match(/iPad/i)) {
      return 'tablet';
    }
    return 'desktop';
  }

  var MIN_VERSIONS = {
    chrome: 16,
    firefox: 22,
    opera: 15,
    msie: 10,
    safari: 7,
  };

  var info = getInfo();

  function isSupported(info) {
    return info.browser in MIN_VERSIONS && info.version >= MIN_VERSIONS[info.browser];
  }

  var canWork = !!window.localStorage && !!window.WebSocket;
  if ((!isSupported(info) && getDeviceType() === 'desktop') || !canWork) {
    var goodButOld = '';
    var localizedStrings = locales[document.documentElement.getAttribute('lang') || 'en'];
    if (info.browser in MIN_VERSIONS) {
      var title = info.browser.charAt(0).toUpperCase() + info.browser.substr(1);
      goodButOld =
        '<p class="outdated-browser-notice__good-but-old">' +
        localizedStrings['note'](title, info.version, MIN_VERSIONS[info.browser]) +
        '</p>';
    }
    var notice = document.createElement('div');
    notice.className = 'outdated-browser-notice__wrapper';
    notice.innerHTML =
      '' +
      '<div class="outdated-browser-notice__table">' +
      '<div class="outdated-browser-notice__cell">' +
      '<div class="outdated-browser-notice">' +
      '<h3 class="outdated-browser-notice__title">' +
      localizedStrings['warning'] +
      '</h3>' +
      goodButOld +
      '<p class="outdated-browser-notice__description">' +
      localizedStrings['recommendation'] +
      '</p>' +
      '<div class="outdated-browser-notice__links">' +
      '<a class="outdated-browser-notice__browser-link" target="_blank" href="http://www.google.com/chrome/">' +
      '<img class="outdated-browser-notice__icon" src="img/browser-icons/chrome.png"/>' +
      '<span class="outdated-browser-notice__browser-title">Chrome</span>' +
      '</a>' +
      '<a class="outdated-browser-notice__browser-link" target="_blank" href="http://www.mozilla-europe.org/">' +
      '<img class="outdated-browser-notice__icon" src="img/browser-icons/firefox.png"/>' +
      '<span class="outdated-browser-notice__browser-title">Firefox</span>' +
      '</a>' +
      '<a class="outdated-browser-notice__browser-link" target="_blank" href="http://www.apple.com/safari/">' +
      '<img class="outdated-browser-notice__icon" src="img/browser-icons/safari.png"/>' +
      '<span class="outdated-browser-notice__browser-title">Safari</span>' +
      '</a>' +
      '<a class="outdated-browser-notice__browser-link" target="_blank" href="http://www.opera.com/">' +
      '<img class="outdated-browser-notice__icon" src="img/browser-icons/opera.png"/>' +
      '<span class="outdated-browser-notice__browser-title">Opera</span>' +
      '</a>' +
      '</div>' +
      (canWork ? '<div class="outdated-browser-notice__ok" id="ok-button">OK</div>' : '') +
      '</div>' +
      '</div>' +
      '</div>';
    window.onload = function () {
      var okButton = document.getElementById('ok-button');
      if (okButton) {
        okButton.onclick = function () {
          document.body.removeChild(notice);
          typeof window['require'] == 'function' && window['require'](['app']);
        };
      }
    };
    document.body.insertBefore(notice, document.body.firstChild);
  } else {
    typeof window['require'] == 'function' && window['require'](['app']);
  }
})();
